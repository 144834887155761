@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/8d966e432e72d5fe-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/5feffe0d28ad606b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/147134ae781d5276-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/e31b16401c3a257b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/d085e2bf1b2c0fad-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/dcee20d5282e14ae-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/ff724a773c7f8a36-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'DMSansFont';
src: url(/_next/static/media/7a9bebb48575ea8c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}.__className_672d0e {font-family: 'DMSansFont'
}.__variable_672d0e {--dm-sans-font: 'DMSansFont'
}

@font-face {
font-family: 'AlbatrosFont';
src: url(/_next/static/media/69e3175217a94d59-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'AlbatrosFont';
src: url(/_next/static/media/e0f84be596c30f03-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'AlbatrosFont';
src: url(/_next/static/media/7ddb04194acd120f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'AlbatrosFont';
src: url(/_next/static/media/deaccf5f3be9b98b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: 'AlbatrosFont';
src: url(/_next/static/media/bbcc228e91d3bd29-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'AlbatrosFont';
src: url(/_next/static/media/fcc7f2e8ec0cd729-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}.__className_1de314 {font-family: 'AlbatrosFont'
}.__variable_1de314 {--albatros-font: 'AlbatrosFont'
}

